// admin/+route.js
export const route = (pageContext) => {
    const { urlPathname } = pageContext

    // If exact match for /admin
    if (urlPathname === '/privacy-policy') {
        return { match: true, routeParams: { '*': '' } }
    }

    // If starts with /admin/
    if (urlPathname.startsWith('/privacy-policy/')) {
        // Get everything after /admin/
        const wildcard = urlPathname.slice('/privacy-policy/'.length)
        return { match: true, routeParams: { '*': wildcard } }
    }

    return { match: false }
}