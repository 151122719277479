// admin/+route.js
export const route = (pageContext) => {
    const { urlPathname } = pageContext

    // If exact match for /admin
    if (urlPathname === '/admin') {
        return { match: true, routeParams: { '*': '' } }
    }

    // If starts with /admin/
    if (urlPathname.startsWith('/admin/')) {
        // Get everything after /admin/
        const wildcard = urlPathname.slice('/admin/'.length)
        return { match: true, routeParams: { '*': wildcard } }
    }

    return { match: false }
}