// admin/+route.js
export const route = (pageContext) => {
    const { urlPathname } = pageContext

    // If exact match for /admin
    if (urlPathname === '/pricing') {
        return { match: true, routeParams: { '*': '' } }
    }

    // If starts with /admin/
    if (urlPathname.startsWith('/pricing/')) {
        // Get everything after /admin/
        const wildcard = urlPathname.slice('/pricing/'.length)
        return { match: true, routeParams: { '*': wildcard } }
    }

    return { match: false }
}